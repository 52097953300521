import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import DoneIcon from "@mui/icons-material/Done";

const RemoveDuplicateFiles = ({ setFileStatus, userUploadFiles, loading }) => {
  const cancelUpload = async (cancelled_file) => {
    let sessionUploadData = JSON.parse(sessionStorage.getItem("uploadFile"));
    let currentFile = sessionUploadData[cancelled_file.name];
    let tempStatus = {
      ...sessionUploadData,
      [cancelled_file.name]: { ...currentFile, isCancelled: true },
    };
    sessionStorage.setItem("uploadFile", JSON.stringify(tempStatus));
    cancelled_file.cancelSource.cancel("User has canceled the file upload.");
    await setFileStatus(tempStatus);
  };

  let myFiles;
  if (loading) {
    let myFilesData = sessionStorage.getItem("uploadFile");
    if (myFilesData) {
      myFiles = JSON.parse(sessionStorage.getItem("uploadFile"));
    }
  }
  return (
    <div id="overlay">
      <div>
        <div
          className="d-flex justify-content-center text-center spinner upload-icon"
          id="loading-bar-spinner"
        >
          <Player
            autoplay
            loop
            src="https://assets3.lottiefiles.com/temp/lf20_edwUDF.json"
            style={{ height: "150px", width: "150px" }}
          ></Player>
        </div>
        <div
          className="spinner upload-files container p-2"
          id="loading-bar-spinner"
        >
          {userUploadFiles && userUploadFiles.length > 0 && (
            <div>
              {userUploadFiles.map((item, index) => (
                <div className="row my-2" key={`${index}-${item.name}`}>
                  <div className="col-7 col-lg-9 fileName">
                    {myFiles[item.name]?.isCancelled ? (
                      <p className="fileName">
                        <del>{item.name}</del>
                      </p>
                    ) : (
                      <p className="fileName">
                        <span>{item.name}</span>
                      </p>
                    )}
                  </div>

                  {myFiles[item.name]?.isUploaded ? (
                    <div className="col-5 col-lg-2">
                      <DoneIcon className="text-success" />
                    </div>
                  ) : (
                    <div className="col-5 col-lg-2">
                      {myFiles[item.name]?.isCancelled ? (
                        <button className="btn-secondary btn">Cancelled</button>
                      ) : (
                        // <button
                        //   className="btn-danger btn"
                        //   onClick={() => cancelUpload(item)}
                        // >
                        //   Cancel
                        // </button>
                        <></>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RemoveDuplicateFiles;
