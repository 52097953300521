import React from "react";

import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";

// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export const ExportCSV = ({csvData}) => {
  const fileName= 'fileslist;'
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    let sheetData = [];
    for (let i = 0; i < csvData.length; i++) {
      sheetData.push({
        id: i + 1,
        filename: csvData[i].filename,
        uploaded_date: csvData[i].uploaded_date,
        filesize: csvData[i].fileSize,
      });
    }
    const ws = XLSX.utils.json_to_sheet(sheetData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // <div className="download-excel">
    // {/* <CloudDownloadIcon
    //   color="primary"
    //   sx={{ fontSize: 60 }}
    //   // fontSize="large"
    //   className="download-button"
    //   onClick={(e) => exportToCSV(csvData, fileName)}
    // // /> }
    // {/* <h5>Download Files list</h5> */}
    // {/* <h5>Export Files list</h5>   */}
    <button
      className="export-button"
      // href="#"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Export Files list
    </button>
    // </div>
  );
};
