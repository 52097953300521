import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/blob.png";
import { ExportCSV } from "../../utils/ExportCSV";

const Header = (props) => {
  let homeRef = useRef(null);
  let advancedRef = useRef(null);
  let uploadRef = useRef(null);
  useEffect(() => {
    const path = window.location.hash;
    if (path === "" || path === "#/") {
      homeRef?.current?.classList.add("active");
      advancedRef?.current?.classList.remove("active");
      uploadRef?.current?.classList.remove("active");
    }
    if (path === "#/advanced") {
      homeRef?.current?.classList.remove("active");
      advancedRef?.current?.classList.add("active");
      uploadRef?.current?.classList.remove("active");
    }
    if (path === "#/upload") {
      homeRef?.current?.classList.remove("active");
      advancedRef?.current?.classList.remove("active");
      uploadRef?.current?.classList.add("active");
    }
  }, [props.loggedIn]);

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-md custom-navbar navbar-dark">
        <img
          className="navbar-brand"
          src={logo}
          id="logo_custom"
          width="140px"
          alt="logo"
        />
        <button
          className="navbar-toggler navbar-toggler-right custom-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {props.loggedIn && (
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto ">
              <li className="nav-item header-a exporter">
                <Link
                  to="/"
                  className="home"
                  id="nav-home"
                  ref={homeRef}
                  onClick={(e) => {
                    homeRef.current.classList.add("active");
                    advancedRef.current.classList.remove("active");
                    uploadRef.current.classList.remove("active");
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item header-a exporter">
                <Link
                  to="/advanced"
                  className="advanced"
                  id="nav-advanced"
                  ref={advancedRef}
                  onClick={(e) => {
                    homeRef.current.classList.remove("active");
                    advancedRef.current.classList.add("active");
                    uploadRef.current.classList.remove("active");
                  }}
                >
                  Advanced
                </Link>
              </li>
              <li className="nav-item header-a exporter">
                <Link
                  to="/upload"
                  ref={uploadRef}
                  className="upload"
                  id="nav-upload"
                  onClick={(e) => {
                    homeRef.current.classList.remove("active");
                    advancedRef.current.classList.remove("active");
                    uploadRef.current.classList.add("active");
                  }}
                >
                  Upload
                </Link>
              </li>
              <li className="nav-item exporter">
                {props.load ? (
                  <span className="text-secondary">Export Files list</span>
                ) : (
                  <ExportCSV csvData={props.fileList} />
                )}
              </li>
              <li className="nav-item">
                <a className="header-a" href="/" onClick={props.handleLogout} >
                  Logout
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav>
      {!props.loggedIn && (
        <div className="main">
          <div className="login mt-5">
            <div className="row">
              <div id="login" className="col-md-3">
                <div className="well login-box">
                  <form action="">
                    {/* <p className="text-danger">{error}</p> */}
                    <div className="login-page">
                      <input
                        type="button"
                        className="btn w-100 mars-bg ssologin"
                        value={props?.loading ? "Loading.." : "Login"}
                        onClick={() => {}}
                      />

                      <a
                        href="https://myaccess.microsoft.com/@mars.onmicrosoft.com#/access-packages/a82459ee-afe4-4c34-8f24-439e7ee38810"
                        className="btn w-100 register"
                        target="_blank"
                      >
                        Register
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
