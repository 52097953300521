
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer col-12">
        <section className="text-center">
            © <script>{currentYear}</script> Mars, Incorporated and its Affiliates. All Rights Reserved
        </section>
    </div>
  );
}

export default Footer;
