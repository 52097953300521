import { BlobServiceClient } from "@azure/storage-blob";
import keys from "../config/config";

const blobSasUrl = keys.azureBlob.blobSasUrl;
// Create a new BlobServiceClient
const blobServiceClient = new BlobServiceClient(blobSasUrl);

// Create a unique name for the container by
// appending the current time to the file name
const containerName = keys.azureBlob.containerName;
const storageAccountName = keys.azureBlob.storageAccountName;

// Get a container client from the BlobServiceClient
const containerClient = blobServiceClient.getContainerClient(containerName);

export const isStorageConfigured = () => {
  return !storageAccountName || !blobSasUrl ? false : true;
};

export const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];
  if (typeof containerClient == "undefined") {
    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(blobSasUrl);
    // get Container - full public read access
    containerClient = blobService.getContainerClient(containerName);
  }
  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    let fileSize;
    let fileSizeInBytes = blob.properties.contentLength;
    let fileSizeInKB = Math.round(fileSizeInBytes * 0.0009765625);
    let fileSizeInMB;

    if (fileSizeInKB >= 1000) {
      fileSizeInMB = Math.round(fileSizeInKB * 0.0009765625);
      fileSizeInMB >= 1000
        ? (fileSize = (fileSizeInMB * 0.001).toFixed(2) + " " + "GB")
        : (fileSize = fileSizeInMB + " " + "MB");
    } else {
      fileSizeInKB === 0
        ? (fileSize = 1 + " " + "KB")
        : (fileSize = fileSizeInKB + " " + "KB");
    }

    // if image is public, just construct URL
    returnedBlobUrls.push({
      pdfUrl: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`,
      createdTime: new Date(blob.properties.createdOn),
      timeStamp: new Date(blob.properties.createdOn).toUTCString(),
      fileSize: fileSize,
    });
  }
  returnedBlobUrls.sort(
    (a, b) => new Date(b.createdTime) - new Date(a.createdTime)
  );
  return returnedBlobUrls;
};

export const uploadFileToBlob = async (file) => {
  if (file) {
    try {
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      await blockBlobClient.uploadData(file);

      // return getBlobsInContainer(containerClient);
      return true;
    } catch(err) {
      return false;
    }
  } else {
    return false;
  }
  //   try {
  //     reportStatus("Uploading files...");
  //     const promises = [];
  //     for (const file of fileInput.files) {
  //       const blockBlobClient = containerClient.getBlockBlobClient(file.name);
  //       promises.push(blockBlobClient.uploadBrowserData(file));
  //     }
  //     await Promise.all(promises);
  //     reportStatus("Done.");
  //     listFiles();
  //   } catch (error) {
  //     reportStatus(error.message);
  //   }
};

export const searchResults = async (value) => {
  const blobService = new BlobServiceClient(blobSasUrl);
  // get Container - full public read access
  let client = blobService.getContainerClient(containerName);
  const listoptions = {
    prefix: value.toString(),
  };
  const iter = client.listBlobsFlat(listoptions).byPage({});
  let response = (await iter.next()).value;
  let searchResult = [];
  for await (const blob of response.segment.blobItems) {
    let fileSize;
    let fileSizeInBytes = blob.properties.contentLength;
    let fileSizeInKB = Math.round(fileSizeInBytes * 0.0009765625);
    let fileSizeInMB;

    if (fileSizeInKB >= 1000) {
      fileSizeInMB = Math.round(fileSizeInKB * 0.0009765625);
      fileSizeInMB >= 1000
        ? (fileSize = (fileSizeInMB * 0.001).toFixed(2) + " " + "GB")
        : (fileSize = fileSizeInMB + " " + "MB");
    } else {
      fileSizeInKB === 0
        ? (fileSize = 1 + " " + "KB")
        : (fileSize = fileSizeInKB + " " + "KB");
    }

    // if image is public, just construct URL
    searchResult.push({
      fileName: blob.name,
      pdfUrl: `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`,
      createdTime: new Date(blob.properties.createdOn),
      timeStamp: new Date(blob.properties.createdOn).toUTCString(),
      fileSize: fileSize,
    });
  }
  return searchResult;
};
