import React from "react";
import Path from "path";
import keys from "../../../config/config";

const DisplayFiles = (item) => {
  return (
    // <div className="mb-4 col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
    //   <div className="card">
    //     <img
    //       src={pdfUrl.includes(".zip") ? zipIcon : fileIcon}
    //       alt="zipIcon"
    //       className="zip-image"
    //     />
    //     <div className="card-body text-center">
    //       <p className="card-content">
    //         <span className="tooltiptext">
    //           {`${Path.basename(pdfUrl)} ${createdTime}`}
    //         </span>
    //         {Path.basename(pdfUrl)}
    //         <br />
    //         <small>{`${createdTime}`}</small>
    //         <br />
    //         <small>{fileSize}</small>
    //       </p>
    // <a
    //   target="_blank"
    //   href={`${pdfUrl}${keys.azureBlob.secureFileDownload}`}
    //   className="btn btn-primary download-button"
    //   rel="noreferrer"
    // >
    //         Download
    //       </a>
    //     </div>
    //   </div>
    // </div>
    <tr className="d-flex">
      <td
        align="left"
        className="col-8"
        style={{ wordWrap: "break-word", padding: "0.3rem" }}
      >
        <a
          target="_blank"
          href={`${item.item.pdfUrl}${keys.azureBlob.secureFileDownload}`}
          rel="noreferrer"
        >
          {Path.basename(item.item.pdfUrl)}
        </a>
      </td>
      <td
        align="left"
        className="col-3"
        style={{ wordWrap: "break-word", padding: "0.3rem" }}
      >
        {item.item.timeStamp}
      </td>
      <td
        align="left"
        className="col-1"
        style={{ wordWrap: "break-word", padding: "0.3rem" }}
      >
        {item.item.fileSize}
      </td>
    </tr>
  );
};
export default DisplayFiles;
