const keys = {
  msalConfig: {
    clientId: "839a80bb-2541-4451-b021-323a67b10de6",
    authority:
      "https://login.microsoftonline.com/2fc13e34-f03f-498b-982a-7cb446e25bc6",
    // redirectUri: "https://packfiles.cloud-effem.com/",
    redirectUri: "https://blob.mars.com/",
  },
  azureBlob: {
    blobSasUrl:
      "https://prdeus2mgspackfilessa.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-12-30T22:26:31Z&st=2024-07-30T14:26:31Z&spr=https&sig=uGXCQRGhoFZtflQy5DsXXjF4Fy%2FxChgm3XsJ1n0%2FHj0%3D",
    containerName: "files",
    storageAccountName: "prdeus2mgspackfilessa",
    secureFileDownload:
      "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-12-30T22:26:31Z&st=2024-07-30T14:26:31Z&spr=https&sig=uGXCQRGhoFZtflQy5DsXXjF4Fy%2FxChgm3XsJ1n0%2FHj0%3D",
    domain: "https://prdeus2mgspackfilessa.blob.core.windows.net",
  },
};

export default keys;
