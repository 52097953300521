import React, { useState } from "react";
import Path from "path";
import InfiniteScroll from "react-infinite-scroll-component";
import DisplayFiles from "../components/FileWrapper/DisplayFiles/DisplayFile";
import ProgressBar from "../ProgressiveBar";
import { Link } from "react-router-dom";

const Advanced = ({ load, blobList }) => {
  const [mainRenderList, setMainRenderList] = useState([]);
  const [currentRenderList, setCurrentRenderList] = useState([]);
  const [checkMore, setCheckMore] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [listCount, setListCount] = useState(50);
  const [listEmpty, setListEmpty] = useState(false);

  const _getFilteredRecord = (il, i, val) => {
    if (!checkMore) {
      setCheckMore(true);
    }
    if (!hasMore) {
      setHasMore(true);
    }
    if (val !== "") {
      // new code
      let tempBlobList = [];

      let splittedSearchText = val.trim().split(" ");
      for (let searchKey of splittedSearchText) {
        for (let blob of blobList) {
          if (
            Path.basename(blob.pdfUrl)
              .toLowerCase()
              .includes(searchKey.toLowerCase()) &&
            searchKey !== " "
          ) {
            tempBlobList.push(blob);
          }
        }
      }

      let listCount = 16;
      if (window.innerWidth >= 4000) {
        setListCount(50);
      } else if (window.innerWidth >= 3500) {
        setListCount(30);
      } else if (window.innerWidth >= 2500) {
        setListCount(24);
      }
      if (splittedSearchText.length >= 2) {
        let countOfBlobList = {};
        tempBlobList.forEach((x) => {
          countOfBlobList[x.pdfUrl] = (countOfBlobList[x.pdfUrl] || 0) + 1;
        });

        const asArray = Object.entries(countOfBlobList);
        const filtered = asArray.filter(
          ([key, value]) => value === splittedSearchText.length
        );
        const blobListCountGTE2 = Object.fromEntries(filtered);

        let arrayBlobListCountGTE2 = [];

        for (const element of tempBlobList) {
          if (blobListCountGTE2.hasOwnProperty(element.pdfUrl)) {
            arrayBlobListCountGTE2.push(element);
          }
        }
        let uniqueTempBlobList = arrayBlobListCountGTE2.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.pdfUrl === value.pdfUrl)
        );

        setMainRenderList([...uniqueTempBlobList]);
        setCurrentRenderList([...uniqueTempBlobList].slice(0, listCount));
        if (uniqueTempBlobList.length <= listCount) {
          setCheckMore(false);
        }
        return [...uniqueTempBlobList].slice(il, i);
      } else {
        setMainRenderList([...tempBlobList]);
        setCurrentRenderList([...tempBlobList].slice(0, listCount));
        if (tempBlobList.length <= listCount) {
          setCheckMore(false);
        }
        return [...tempBlobList].slice(il, i);
      }
    } else {
      return [...blobList].slice(il, i);
    }
  };

  const fetchMoreData = () => {
    let listCount;
    if (window.innerWidth >= 4000) {
      listCount = 50;
    } else if (window.innerWidth >= 3500) {
      listCount = 40;
    } else if (window.innerWidth >= 2300) {
      listCount = 24;
    } else {
      listCount = 16;
    }
    let addCurrentData = mainRenderList.slice(
      currentRenderList.length,
      currentRenderList.length + listCount
    );

    let tempCurrentData = [...currentRenderList, ...addCurrentData];
    setCurrentRenderList([...tempCurrentData]);
    if (tempCurrentData.length === mainRenderList.length) {
      setHasMore(false);
      setCheckMore(false);
    }
  };
  const loadMoreNoSearch = () => {
    if (listCount > blobList.length) {
      setListEmpty(true);
    }
    setListCount(listCount + 200);
  };

  return (
    <>
      <div>
        <h5 className="fileNameConvention">
          <b>File naming convention:</b> [Unique code]_[Artwork
          type]_[Brand*]_[Sub-brand*]_[Count/Weight*]_[Pack
          type*]_[Activity]_[Country]_[Year]_[Version] *when applicable
        </h5>
      </div>
      {load && (
        <div className="container">
          <div className="d-flex justify-content-center">
            <ProgressBar />
          </div>
          <p className="text-center">
            Loading all files for advanced multiple term search ...
          </p>
          <div className="d-flex justify-content-center">
            <Link
              to="/"
              onClick={() => {
                document
                  .getElementById("nav-advanced")
                  .classList.remove("active");
                document.getElementById("nav-home").classList.add("active");
                document
                  .getElementById("nav-upload")
                  .classList.remove("active");
              }}
            >
              <button className="btn btn-danger ">Cancel</button>
            </Link>
          </div>
        </div>
      )}
      {!load && blobList.length !== 0 && (
        <div>
          <div className="row mx-5 mb-5">
            <div className="col-md-3"></div>
            <div className="search col-md-6">
              <i className="fa fa-search"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Enter search terms"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  _getFilteredRecord(0, 0, e.target.value);
                }}
              />
            </div>
            <div className="col-md-3">
              {searchText !== "" ? (
                <p className="pt-2 mb-0 fileno-text">
                  {`${mainRenderList.length} files found`}
                </p>
              ) : (
                <p className="pt-2 mb-0 fileno-text">
                  {`${blobList.length} files found`}
                </p>
              )}
            </div>
          </div>
          {searchText === "" ? (
            <div className="container-fluid table-responsive load-more-container ">
              <table className="table table-striped">
                <thead>
                  <tr className="d-flex">
                    <th align="center" className="col-8">
                      File Name
                    </th>
                    <th align="center" className="col-3">
                      Last Modified
                    </th>
                    <th align="center" className="col-1">
                      File Size
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {blobList.slice(0, listCount).map((item, index) => {
                    return (
                      <DisplayFiles
                        item={item}
                        key={`${item.pdfUrl}-${index}`}
                      />
                    );
                  })}
                </tbody>
              </table>
              {!listEmpty ? (
                <div>
                  <button className="btn btn-link" onClick={loadMoreNoSearch}>
                    Load More
                  </button>
                </div>
              ) : (
                <div>
                  <p className="text-center p-3">
                    <b>
                      These are all the files which match the search terms
                      entered.
                    </b>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="container-fluid table-responsive">
              {currentRenderList.length > 0 ? (
                <InfiniteScroll
                  dataLength={currentRenderList.length} //This is important field to render the next data
                  next={fetchMoreData}
                  hasMore={hasMore}
                >
                  <table className="table table-striped">
                    <thead>
                      <tr className="d-flex">
                        <th align="center" className="col-8">
                          File Name
                        </th>
                        <th align="center" className="col-3">
                          Last Modified
                        </th>
                        <th align="center" className="col-1">
                          File Size
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRenderList.map((item, index) => {
                        return (
                          <DisplayFiles
                            item={item}
                            key={`${item.pdfUrl}-${index}`}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                  {checkMore ? (
                    <div>
                      <p className="text-center p-3">Loading....</p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center p-3">
                        <b>
                          These are all the files which match the search terms
                          entered.
                        </b>
                      </p>
                    </div>
                  )}
                </InfiniteScroll>
              ) : (
                <p style={{ textAlign: "center" }}>
                  <b>Oops! no results found.</b>
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Advanced;
