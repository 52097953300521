import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Path from "path";
import {
  getBlobsInContainer,
  isStorageConfigured,
} from "./utils/azure-storage-blob";
import Footer from "./components/Footer/Footer";
import { initializeApp } from "firebase/app";
import { signOut, getAuth } from "firebase/auth";
import * as msal from "@azure/msal-browser";
import keys from "./config/config";
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Advanced from "./pages/AdvancedSearch";
import UploadFile from "./components/UploadFile/UploadFile";
import RemoveDuplicateFiles from "./components/RemoveDuplicateFiles/Index";

const msalConfig = {
  auth: {
    clientId: keys.msalConfig.clientId,
    authority: keys.msalConfig.authority,
    redirectUri: keys.msalConfig.redirectUri,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const firebaseConfig = {
  apiKey: "AIzaSyCPCFViUz9ogxmGgs2a7dDFUt6bqnfc54o",
  authDomain: "productartworks.firebaseapp.com",
  projectId: "productartworks",
  storageBucket: "productartworks.appspot.com",
  messagingSenderId: "447656882576",
  appId: "1:447656882576:web:d61e5517a0dfa03590c315",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

const storageConfigured = isStorageConfigured();
const App = () => {
  const [totalFiles, setTotalFiles] = useState([]);
  const [exportFileList, setExportFileList] = useState([]);

  const [loggedIn, setLoggedIn] = useState(false);

  // all blobs in container
  const [blobList, setBlobList] = useState([]);

  const [load, setLoad] = useState(false);
  const [userUploadFiles, setUserUploadFiles] = useState("");

  const [fileStatus, setFileStatus] = useState({});

  useEffect(() => {
    handleSSOLogin();
  }, []);

  useEffect(() => {}, [exportFileList]);

  const handleSSOLogin = async () => {
    setLoad(true);
    const loggedInCheck = localStorage.getItem("ms-login-info");

    const silentRequest = {
      scopes: ["User.Read"],
      //loginHint: "user@contoso.com"
    };
    try {
      const loginResponse = await msalInstance.ssoSilent(silentRequest);
      localStorage.setItem("ms-login-info", JSON.stringify(loginResponse));
      _fetchBlobData();
      setLoggedIn(true);
    } catch (err) {
      if (err) {
        try {
          const loginResponse = await msalInstance.loginPopup(silentRequest);
          localStorage.setItem("ms-login-info", JSON.stringify(loginResponse));
          _fetchBlobData();
          setLoggedIn(true);
        } catch (err) {
          // console.log(err);
        }
      } else {
        // handle error
        setLoggedIn(false);
      }
    }
  };

  const _fetchBlobData = () => {
    getBlobsInContainer().then((data) => {
      setLoad(false);
      setBlobList(data);
      const tempFiles = [];
      const tempFilesList = [];

      data.map((listItem) => {
        tempFiles.push(Path.basename(listItem.pdfUrl));
        tempFilesList.push({
          filename: Path.basename(listItem.pdfUrl),
          uploaded_date: listItem.createdTime + " ",
          fileSize: listItem.fileSize,
        });
      });
      setTotalFiles(tempFiles);
      setExportFileList(tempFilesList);
    });
  };

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    msalInstance.logoutRedirect({
      onRedirectNavigate: () => {
        localStorage.clear();

        return false;
      },
    });
    signOut(auth);
    setLoggedIn(false);
  };

  return (
    <>
      <Header
        loggedIn={loggedIn}
        handleLogout={handleLogout}
        fileList={exportFileList}
        load={load}
      />
      <div className="main">
        {loggedIn && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/advanced"
              element={<Advanced load={load} blobList={blobList} />}
            />
            <Route
              path="/upload"
              element={
                <UploadFile
                  totalFiles={totalFiles}
                  setUserUploadFiles={setUserUploadFiles}
                  setFileStatus={setFileStatus}
                  setLoading={setLoading}
                  setLoad={setLoad}
                  getBlobsInContainer={getBlobsInContainer}
                  setBlobList={setBlobList}
                  setTotalFiles={setTotalFiles}
                  setExportFileList={setExportFileList}
                />
              }
            />
            <Route path="*" element={<Home />} />
          </Routes>
        )}
      </div>
      {loading && (
        <RemoveDuplicateFiles
          setFileStatus={setFileStatus}
          userUploadFiles={userUploadFiles}
          loading={loading}
        />
      )}
      <Footer />
    </>
  );
};

export default App;
