import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import axios, { isCancel } from "axios";
import Path from "path";
import keys from "../../config/config";
import uploadImg from "../../assets/images/cloud-upload-regular-240.png";
import "../../../src/drop-file-input.css";
import {
  searchResults,
  uploadFileToBlob,
} from "../../utils/azure-storage-blob";

const UploadFile = ({
  totalFiles,
  setUserUploadFiles,
  setFileStatus,
  setLoading,
  setLoad,
  getBlobsInContainer,
  setBlobList,
  setTotalFiles,
  setExportFileList,
}) => {
  const [inputKey, setInputKey] = useState(Math.random().toString(36));
  const [spinner, setSpinner] = useState(false);
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = async (event) => {
    let duplicateFiles = [];
    let extensionList = ["zip", "7z", "tar", "sit", "z", "gz", "arc", "rar"];
    let correctFileEx = [];
    let wrongFileEx = [];
    if (totalFiles.length > 0) {
      if (Object.values(event.target.files).length > 0) {
        Object.values(event.target.files).map((file) => {
          if (totalFiles.includes(file.name)) {
            duplicateFiles.push(file.name);
          }
          const filearr = file.name.split(".");
          const strng = filearr[filearr.length - 1].toString();
          if (extensionList.includes(strng)) {
            correctFileEx.push(file);
          } else wrongFileEx.push(file);
          wrongFileEx = wrongFileEx.filter(
            (elm, index) => wrongFileEx.indexOf(elm) === index
          );
          correctFileEx = correctFileEx.filter(
            (elm, index) => correctFileEx.indexOf(elm) === index
          );

          return event.target.files;
        });
      }
    } else {
      setSpinner(true);
      if (Object.values(event.target.files).length > 0) {
        let files = [...event.target.files];
        let prefixSearchResult = [];
        let fileNames = files.map((elm) => elm.name);
        for (let elm of fileNames) {
          const res = await searchResults(elm);
          prefixSearchResult.push(...res);
        }

        Object.values(event.target.files).map((file) => {
          if (prefixSearchResult.some((elm) => elm.fileName === file.name)) {
            duplicateFiles.push(file.name);
          }
          const filearr = file.name.split(".");
          const strng = filearr[filearr.length - 1].toString();
          if (extensionList.includes(strng)) {
            correctFileEx.push(file);
          } else wrongFileEx.push(file);
          wrongFileEx = wrongFileEx.filter(
            (elm, index) => wrongFileEx.indexOf(elm) === index
          );
          correctFileEx = correctFileEx.filter(
            (elm, index) => correctFileEx.indexOf(elm) === index
          );
          return event.target.files;
        });
        setSpinner(false);
      }
    }
    if (wrongFileEx.length > 0) {
      Swal.fire({
        title: "File Extension Not Allowed",
        text:
          "The following extension is not allowed : " +
          wrongFileEx.map((elm) => elm.name.split(".")[1].toString()),
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) return result;
      });
    } else if (duplicateFiles.length > 0) {
      Swal.fire({
        title: "Duplicate file upload",
        text: "You already have these files: " + duplicateFiles.join(" ,"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Remove Duplicates & Upload",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setUserUploadFiles(correctFileEx);
          let mapFiles = correctFileEx;
          let temp_obj = {};
          for (const element of mapFiles) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            element.cancelSource = source;
            let obj = {};
            obj.isUploaded = false;
            obj.isCancelled = false;
            obj.cancelSource = source;
            temp_obj[element.name] = obj;
          }
          sessionStorage.setItem("uploadFile", JSON.stringify(temp_obj));
          setFileStatus(temp_obj);

          for (const element of mapFiles) {
            await onFileUpload(element);
          }
          setTimeout(() => {
            setLoading(false);
          }, 500);
          await fetchDataAfterUpload();
        }
        return result;
      });
    } else if (correctFileEx.length > 0) {
      setUserUploadFiles(correctFileEx);
      let mapFiles = correctFileEx;
      let temp_obj = {};
      for (const element of mapFiles) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        element.cancelSource = source;
        let obj = {};
        obj.isUploaded = false;
        obj.isCancelled = false;
        obj.cancelSource = source;
        temp_obj[element.name] = obj;
      }
      sessionStorage.setItem("uploadFile", JSON.stringify(temp_obj));
      setFileStatus(temp_obj);

      for (const element of mapFiles) {
        await onFileUpload(element);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
      await fetchDataAfterUpload();
    }
  };
  const putBlob = async (fileSelected) => {
    const data = new FormData();
    data.append("file", fileSelected);

    const options = {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": fileSelected.type,
      },

      cancelToken: fileSelected.cancelSource.token,
    };

    return axios
      .put(
        `${keys.azureBlob.domain}/${keys.azureBlob.containerName}/${fileSelected.name}${keys.azureBlob.secureFileDownload}`,
        data,
        options
      )
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const onFileUpload = async (fileSelected) => {
    setLoading(true);

    // const fileUploadStatus = await putBlob(fileSelected);
    const fileUploadStatus = await uploadFileToBlob(fileSelected);
    if (fileUploadStatus) {
      let sessionUploadData = JSON.parse(sessionStorage.getItem("uploadFile"));
      let currentFile = sessionUploadData[fileSelected.name];
      let tempStatus = {
        ...sessionUploadData,
        [fileSelected.name]: { ...currentFile, isUploaded: true },
      };
      sessionStorage.setItem("uploadFile", JSON.stringify(tempStatus));

      await setFileStatus(tempStatus);
    }
  };

  const fetchDataAfterUpload = async () => {
    setLoad(true);
    const blobsInContainer = await getBlobsInContainer();
    setLoad(false);
    setBlobList(
      blobsInContainer.sort(function (a, b) {
        return new Date(b.createdTime) - new Date(a.createdTime);
      })
    );

    let tempFiles = [];
    let tempFilesList = [];
    blobsInContainer.map((listItem) => {
      tempFiles.push(Path.basename(listItem.pdfUrl));
      tempFilesList.push({
        filename: Path.basename(listItem.pdfUrl),
        uploaded_date: listItem.createdTime + " ",
        fileSize: listItem.fileSize,
      });
    });
    setTotalFiles(tempFiles);
    setExportFileList(tempFilesList);
    setInputKey(Math.random().toString(36));
  };

  return (
    <div>
      <div>
        <h5 className="fileNameConvention">
          <b>File naming convention:</b> [Unique code]_[Artwork
          type]_[Brand*]_[Sub-brand*]_[Count/Weight*]_[Pack
          type*]_[Activity]_[Country]_[Year]_[Version] *when applicable
        </h5>
      </div>
      <div className="row">
        <div className="display-form">
          {spinner && <div className="lds-dual-ring"></div>}
          {!spinner && (
            <div
              ref={wrapperRef}
              className="drop-file-input"
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div className="drop-file-input__label">
                <img src={uploadImg} alt="" />
                <p>
                  Drag & Drop your files here <br />
                  or <br />
                  Browse
                </p>
              </div>
              <input
                type="file"
                onChange={onFileDrop}
                key={inputKey || ""}
                multiple
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
