import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { searchResults } from "../utils/azure-storage-blob";
import InfiniteScroll from "react-infinite-scroll-component";
import DisplayFiles from "../components/FileWrapper/DisplayFiles/DisplayFile";

const Home = () => {
  const [mainRenderList, setMainRenderList] = useState([]);
  const [currentRenderList, setCurrentRenderList] = useState([]);
  const [checkMore, setCheckMore] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);

  useEffect(() => {
    let timeOutId;
    setIsSearchResult(false);
    if (searchKey.length >= 2) {
      timeOutId = setTimeout(getSearchResult, 1000);
    } else {
      setMainRenderList([]);
      setCurrentRenderList([]);
    }
    return () => clearTimeout(timeOutId);
  }, [searchKey]);

  const getSearchResult = () => {
    setSpinner(true);
    let searchResultArray = [];
    if (!checkMore) {
      setCheckMore(true);
    }
    if (!hasMore) {
      setHasMore(true);
    }
    if (searchKey !== "") {
      let listCount;
      if (window.innerWidth >= 4000) {
        listCount = 50;
      } else if (window.innerWidth >= 3500) {
        listCount = 30;
      } else if (window.innerWidth >= 2500) {
        listCount = 24;
      } else {
        listCount = 16;
      }
      // setMainRenderList([]);
      // setCurrentRenderList([]);
      searchResults(searchKey)
        .then((res) => {
          res.map((listItem, index) => {
            return searchResultArray.push({
              pdfUrl: listItem.pdfUrl,
              timeStamp: listItem.timeStamp,
              fileSize: listItem.fileSize,
              index: index,
            });
          });
          setSpinner(false);
          setIsSearchResult(true);
          setMainRenderList([...searchResultArray]);
          setCurrentRenderList([...searchResultArray].slice(0, listCount));
          if (searchResultArray.length <= listCount) {
            setCheckMore(false);
          }
        })
        .catch((err) => {
          setSpinner(false);
        });
    }
  };

  const fetchMoreData = () => {
    let listCount;
    if (window.innerWidth >= 4000) {
      listCount = 50;
    } else if (window.innerWidth >= 3500) {
      listCount = 40;
    } else if (window.innerWidth >= 2300) {
      listCount = 24;
    } else {
      listCount = 16;
    }
    let addCurrentData = mainRenderList.slice(
      currentRenderList.length,
      currentRenderList.length + listCount
    );

    let tempCurrentData = [...currentRenderList, ...addCurrentData];
    setCurrentRenderList([...tempCurrentData]);
    if (tempCurrentData.length === mainRenderList.length) {
      setHasMore(false);
      setCheckMore(false);
    }
  };

  return (
    <div>
      <div>
        <h2 className="home-search-text">
          <b>Search for unique file code</b>
        </h2>
      </div>
      <div className="row mx-5 mb-5">
        <div className="col-md-3"></div>
        <div className="search col-md-6">
          <i className="fa fa-search"></i>
          <input
            type="text"
            className="form-control"
            placeholder="Enter unique code"
            onChange={(e) => {
              setSearchKey(e.target.value.trim());
            }}
          />
        </div>
        <div className="col-md-3">
          {searchKey !== "" && mainRenderList.length > 0 && !spinner && (
            <p className="pt-2 mb-0 fileno-text">
              {`${mainRenderList.length} files found`}
            </p>
          )}
        </div>
      </div>
      <div className="container-fluid table-responsive">
        {spinner && <div className="lds-dual-ring"></div>}
        {currentRenderList.length > 0 && !spinner ? (
          <InfiniteScroll
            dataLength={currentRenderList.length} //This is important field to render the next data
            next={fetchMoreData}
            hasMore={hasMore}
          >
            <table className="table table-sm table-striped">
              <thead>
                <tr className="d-flex">
                  <th align="center" className="col-8">
                    File Name
                  </th>
                  <th align="center" className="col-3">
                    Last Modified
                  </th>
                  <th align="center" className="col-1">
                    File Size
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRenderList.map((item, index) => {
                  return (
                    <DisplayFiles item={item} key={`${item.pdfUrl}-${index}`} />
                  );
                })}
              </tbody>
            </table>

            {checkMore ? (
              <div>
                <p className="text-center p-3">Loading ....</p>
              </div>
            ) : (
              <div>
                <p className="text-center p-3">
                  <b>
                    This is all we found, click
                    <Link
                      to="/advanced"
                      onClick={() => {
                        document
                          .getElementById("nav-advanced")
                          .classList.add("active");
                        document
                          .getElementById("nav-home")
                          .classList.remove("active");
                        document
                          .getElementById("nav-upload")
                          .classList.remove("active");
                        window.scroll(0, 0);
                      }}
                    >
                      <button className="here-button">here</button>
                    </Link>
                    to perform an advanced search on multiple terms.
                  </b>
                </p>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <p style={{ textAlign: "center" }}>
            {/* <b>Opps, no results found</b> */}
          </p>
        )}
        {searchKey.length >= 2 &&
          searchKey !== "" &&
          currentRenderList.length === 0 &&
          !spinner &&
          isSearchResult && (
            <p style={{ textAlign: "center" }}>
              <b>Oops, no results found</b>
            </p>
          )}
      </div>
    </div>
  );
};

export default Home;
